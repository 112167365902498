<template>
  <div style="margin-bottom: -2rem">
    <translate
      v-if="isTranslateMode"
      :translations="translations"
      :translation-active="translationActive"
      :title="restaurant.name"
      :primary-language="primaryLanguage"
      @addLanguage="onAddLanguage"
      @setTranslationActive="translationActive = $event"
      @setTranslateMode="setTranslateMode"
      @translate="translateAll"
      @clearTranslation="onClearTranslation"
    >
      <template v-slot:default>
        <b-card>
          <b-form-group :label="$t('Restaurant Name')">
            <validation-provider
              #default="{ errors }"
              name="restaurant_name"
              rules="required"
            >
              <b-form-input
                v-model="name"
                :placeholder="$t('My Restaurant')"
                :state="errors.length ? false : null"
                @blur="onBlurName"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('restaurantMessageLabel')"
            class="mb-0"
          >
            <validation-provider
              #default="{ errors }"
              name="restaurant_message"
              :rules="`max:${restaurantMessageLimit}`"
            >
              <b-form-textarea
                v-model="description"
                :placeholder="$t('restaurantMessageLabelPlaceholder')"
                rows="3"
                no-resize
                :state="errors.length ? false : null"
                @blur="onBlurDescription(!!errors.length)"
              />
            </validation-provider>
          </b-form-group>
        </b-card>
      </template>
      <template v-slot:translation="{ translation }">
        <b-card>
          <b-form-group :label="$t('Restaurant Name')">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.name"
                :placeholder="$t('My Restaurant')"
                :disabled="isSubscriptionInactiveOrBasic"
                @blur="requests.push('patchTranslationActive')"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('name')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="$t('restaurantMessageLabel')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.description"
                :placeholder="$t('restaurantMessageLabelPlaceholder')"
                rows="3"
                no-resize
                :disabled="isSubscriptionInactiveOrBasic"
                @blur="requests.push('patchTranslationActive')"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('description')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
      </template>
    </translate>
    <template v-else>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card>
          <b-row class="d-flex">
            <b-col>
              <validation-provider
                #default="{ errors, failedRules }"
                name="restaurant_name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  class="input-h input-h4"
                  :placeholder="$t('Restaurant Name')"
                  :state="errors.length ? false : null"
                  @blur="onBlurName"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredRestaurantName') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-col>
            <b-col
              cols="auto"
              style="padding-top: 9px"
            >
              <div class="d-flex">
                <icon-translate
                  v-b-tooltip.hover.top="$t('Translate')"
                  role="button"
                  class="size-20"
                  @click.native="isTranslateMode = true"
                />
                <!--<feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  class="ml-2 text-danger"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="onClickRemoveRestaurant"
                />-->
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card :title="$t('settingsCard1Title')">
          <b-form-group :label="$t('Primary Menu Language')">
            <v-select
              v-model="primaryLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="longName"
              :clearable="false"
              :options="optionsPrimaryLanguage"
              @search:blur="onBlurPrimaryLanguage"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
            </v-select>
          </b-form-group>
          <b-form-group
            id="languages"
            :label="$t('Additional Menu Languages')"
          >
            <v-select
              id="select-additional-languages"
              multiple
              label="longName"
              :placeholder="$t('Choose languages')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :close-on-select="false"
              :selectable="() => !isAdditionalLanguagesDisabled"
              :options="optionsForTranslationLanguages"
              :value="translationLanguages"
              @input="onInputTranslationLanguages"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="isAdditionalLanguagesDisabled"
              target="select-additional-languages"
              triggers="focus"
            >
              <div class="pt-50 pb-50">
                <span>{{ $t('To add more languages you need to upgrade your subscription') }}</span>
                <b-button
                  block
                  size="sm"
                  class="mt-50"
                  variant="primary"
                  @click="$router.push({ name: 'billing' })"
                >
                  {{ $t('Upgrade') }}
                </b-button>
              </div>
            </b-tooltip>
          </b-form-group>
          <hr class="mt-2 mb-2">
          <b-form-group :label="$t('Measurement Units')">
            <v-select
              v-model="measurementUnit"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="measurementUnitsOptions"
              @search:blur="onBlurMeasurementUnit"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$t('Primary Currency')">
            <v-select
              v-model="currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :clearable="false"
              :options="currencies"
              @search:blur="onBlurCurrency"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
            </v-select>
          </b-form-group>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="showCurrencySymbol"
              switch
              @change="onChangeShowCurrencySymbol"
            />
            {{ $t('settingsLabelShowCurrency') }}
            <div class="ml-5 d-flex align-items-center">
              <b-form-radio
                v-model="showCurrencySymbolInFront"
                :value="true"
                @change="onChangeShowCurrencySymbolInFront"
              >
                {{ $t('settingsLabelShowCurrencyBefore') }}
              </b-form-radio>
              <b-form-radio
                v-model="showCurrencySymbolInFront"
                :value="false"
                class="ml-2"
                @change="onChangeShowCurrencySymbolInFront"
              >
                {{ $t('settingsLabelShowCurrencyAfter') }}
              </b-form-radio>
            </div>
          </div>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card :title="$t('settingsCard2Title')">
          <b-form-group :label="$t('Restaurant Address')">
            <b-form-input
              v-model="fullAddress"
              :placeholder="$t('555 Main Street, My City, My Country')"
              @blur="onBlurAddress"
            />
          </b-form-group>
          <b-form-group :label="$t('labelRestaurantEmail')">
            <validation-provider
              #default="{ errors, failedRules }"
              name="restaurant_email"
              rules="email"
            >
              <b-form-input
                v-model="emailRestaurant"
                placeholder="john@example.com"
                :state="errors.length ? false : null"
                @blur="onBlurEmail(!!errors.length)"
              />
              <small class="text-danger">
                <template v-if="failedRules.email">{{ $t('validationErrorEmailRestaurant') }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Restaurant Phone Number')">
            <b-form-input
              v-model="phoneNumber"
              placeholder="+16462806850"
              @blur="onBlurPhone"
            />
            <b-form-checkbox-group
              v-model="messenger"
              class="mt-50"
              :options="messengerOptions"
              @change="onChangeMessenger"
            />
          </b-form-group>
          <b-form-group :label="$t('Restaurant Type')">
            <v-select
              v-model="type"
              :placeholder="$t('placeholderRestaurantType')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :clearable="false"
              :options="restaurantTypes"
              @search:blur="onBlurType"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
              <template #option="option">
                {{ $t(`_restType.${option.name}`) }}
              </template>
              <template #selected-option="option">
                {{ $t(`_restType.${option.name}`) }}
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$t('Type(s) of Cuisine')">
            <v-select
              v-model="cuisine"
              multiple
              :placeholder="$t('placeholderRestaurantCuisine')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :close-on-select="false"
              :options="restaurantCuisines"
              @search:blur="onBlurCuisines"
              @option:deselected="onOptionDeselectedCuisines"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
              <template #option="option">
                {{ $t(`_restCuisine.${option.name}`) }}
              </template>
              <template #selected-option="option">
                {{ $t(`_restCuisine.${option.name}`) }}
              </template>
            </v-select>
          </b-form-group>
          <b-form-group
            :label="$t('settingsLabelTimezone')"
            class="mb-0"
          >
            <v-select
              v-model="timezone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="timezones"
              label="name"
              :clearable="false"
              :reduce="i => i.id"
              @search:blur="onBlurTimezone"
            >
              <template #no-options>
                {{ $t('The list is empty') }}
              </template>
            </v-select>
          </b-form-group>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-title class="mb-0">
            {{ $t('settingsCard3Title') }}
          </b-card-title>
          <small class="d-block mt-75 mb-3">
            {{ $t('settingsCard3Message') }}
          </small>
          <div class="card-inner mb-3">
            <b-card
              :header="$t('cardYoutubeLinksTitle')"
              class="mb-0"
            >
              <small class="d-block mb-75">
                {{ $t('cardYoutubeLinksInfo') }}
              </small>
              <youtube-links
                :value="videos"
                @input="onInputYoutubeLinks"
              />
            </b-card>
          </div>
          <images-limitation />
          <b-form-group
            :label="$t('Restaurant Logo')"
            class="mb-2"
          >
            <images
              :size="{ width: 160, height: 160 }"
              :is-multiple="false"
              :value="logo"
              :update-mutation="UPDATE_RESTAURANT"
              :update-variables="(val) => ({
                updateRestaurantId: restaurant ? restaurant.id : null,
                logo: val.length ? val[0].id : null,
              })"
              @input="onInputLogo"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Restaurant Photos')"
            class="mb-3"
          >
            <images
              :size="{ width: 800, height: 1000 }"
              :is-multiple="true"
              :value="images"
              :update-mutation="UPDATE_RESTAURANT"
              :update-variables="(val) => ({
                updateRestaurantId: restaurant ? restaurant.id : null,
                images: val.map(i => i.id),
              })"
              @input="onInputImages"
            />
          </b-form-group>
          <b-form-group :label="$t('restaurantMessageLabel')">
            <validation-provider
              #default="{ errors, failedRules }"
              name="restaurant_message"
              :rules="`max:${restaurantMessageLimit}`"
            >
              <b-form-textarea
                v-model="description"
                :placeholder="$t('restaurantMessageLabelPlaceholder')"
                rows="3"
                no-resize
                :state="errors.length ? false : null"
                @blur="onBlurDescription(!!errors.length)"
              />
              <small
                v-if="errors.length"
                class="text-danger"
              >
                <template v-if="failedRules.max">{{ $t('validationErrorMaxWelcomeMessage', { slot: restaurantMessageLimit }) }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
              <div
                v-else
                class="text-right"
              >
                <small>{{ description.length }} / {{ restaurantMessageLimit }}</small>
              </div>
            </validation-provider>
          </b-form-group>
          <div class="card-inner">
            <b-card
              :header="$t('Social Media Links')"
              class="mb-0"
            >
              <div
                v-for="(link, index) in socialLinks"
                :key="link.id"
                :class="[{ 'mb-50': socialLinks.length !== index + 1 }]"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon :icon="getSocialLinkIcon(link.target)" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="link.target"
                    :placeholder="$t('Enter URL')"
                    @blur="onBlurSocialLinks"
                  />
                  <b-input-group-append
                    v-if="socialLinks.length !== index + 1"
                    is-text
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      role="button"
                      size="16"
                      @click="removeSocialLink(link.id)"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-card>
          </div>
        </b-card>
      </b-overlay>
    </template>
    <modal-confirm-delete
      v-model="isModalConfirmDeleteTranslationLanguage"
      :title="translationLanguageToRemove ? $t('confirmDeleteLanguageTitle', { languageName: translationLanguageToRemove.longName }) : ''"
      :message="translationLanguageToRemove ? $t('confirmDeleteLanguageMessage', { languageName: translationLanguageToRemove.longName }) : ''"
      @delete="onDeleteTranslationLanguage"
    />
  </div>
</template>

<script>
import mixinRestaurantSettings from '@/mixins/restaurant-settings'

export default {
  name: 'Settings',
  mixins: [
    mixinRestaurantSettings,
  ],
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'
</style>
